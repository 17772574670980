import React, { useEffect } from "react"
import {
  ResourceFilters,
  ResourcePager,
  SearchBar,
  Layout,
  CenteredLayout,
  Loader,
} from "@components"
import { useResources } from "../common/hooks"
import { getResources } from "@services"
import { sortByDate } from "@utilities"
import { RESOURCE_FIELDS, SORT_DIRECTION } from "../common/constants"
import scholarsImage from "../images/people.jpg"

const FutureReady = () => {
  const {
    resourcesStates,
    handleInitialState,
    handleFilters,
    handleSearch
  } = useResources();

  useEffect(() => {
    const loadResources = async () => {
      const resources = await getResources("future-ready")
      const sortedResources = sortByDate(
        resources,
        RESOURCE_FIELDS.lastModifiedDateTime,
        SORT_DIRECTION.desc
      )
      handleInitialState(sortedResources)
    }
    loadResources()
  }, [])

  return (
    <Layout>
      <div className="post-container p-8">
        <div className="blog-post">
          <div className="bg-cover bg-center -m-8 mb-6" style={{ backgroundImage: `url(${scholarsImage})` }}>
            <div className="bg-black bg-opacity-50 h-full w-full flex py-24 px-8">
              <h1 className="text-white">Future Ready NYC</h1>
            </div>
          </div>
          <div
            className="blog-post-content"
          />
          <p>In our collaboration with the New York City Department of Education, Northwell is proud to provide
          healthcare career education and employment pathways to high school students in the FutureReady
          NYC program. We aim to support students throughout high school by providing programs and services
          as follows:
                    <ul>
              <li>9<sup>th grade</sup>: live learning days for career panels and healthcare simulations</li>
              <li>10<sup>th grade</sup> grade: site visits to Northwell facilities during Career Discovery Week</li>
              <li>11<sup>th grade</sup> grade: participation in the Spark Challenge as part of the workplace learning initiative</li>
              <li>12<sup>th grade</sup> grade: paid internships in Northwell facilities</li>
            </ul>
          </p>
          <h3>Have any questions?</h3>
          <p>Visit the FAQ <a href="/future-ready-faq">here</a>.</p>
          <p>Please see the resources below for access to:
                    <ul>
              <li>Supporting materials for upcoming events, including attendee links</li>
              <li>Recordings of previous online FutureReady NYC events</li>
              <li>Access to previously recorded Career Conversations videos with Northwell team members</li>
            </ul>
          </p>
        </div>
      </div>
      <div>
        <CenteredLayout className="bg-nwh-light-gray">
          <SearchBar onSearch={handleSearch} className="mb-6" />
          <ResourceFilters allResources={resourcesStates.allResources} filterKeys={resourcesStates.filterKeys} onChange={handleFilters} />
        </CenteredLayout>
        <CenteredLayout>
          {!resourcesStates.isLoading && <ResourcePager data={resourcesStates.results} />}
          {resourcesStates.isLoading && <Loader label="loading..." />}
        </CenteredLayout>
      </div>
    </Layout>
  )
}

export default FutureReady
